<template>
  <v-navigation-drawer
    app
    :mini-variant.sync="drawer.mini"
    :permanent="drawer.permanent"
    :expand-on-hover="drawer.expandOnHover"
  >
    <v-list dense>
      <v-list-item class="px-2">
        <v-list-item-avatar color="green darken-4" class="justify-center">
          <span class="white--text headline">{{
            logger.acc_name.substr(0, 1)
          }}</span>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item v-if="!drawer.mini">
        <v-list-item-content>
          <v-list-item-title class="subtitle-1">
            {{ logger.acc_name }}｜{{ logger.company }}
          </v-list-item-title>
          <v-divider class="mt-1"></v-divider>
          <v-list-item-subtitle>
            <div
              class="green--text text--darken-4"
              v-text="`${logger.acc_id}｜${logger.email}`"
            ></div>
          </v-list-item-subtitle>
          <v-list-item-action class="mx-0 my-0 mt-2">
            <v-btn
              outlined
              color="green darken-4"
              small
              @click="goLinks('/logout')"
              >登出</v-btn
            >
          </v-list-item-action>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list
      dense
      color="green lighten-4"
      class="py-0"
      v-if="!drawer.expandOnHover"
    >
      <v-list-item @click="clickOnOff()">
        <v-list-item-icon>
          <v-icon
            class="my-0 green--text text--darken-4"
            v-text="drawer.mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"
          ></v-icon>
        </v-list-item-icon>
        <v-list-item-title
          class="subtitle-2 green--text text--darken-4"
          v-text="drawer.mini ? '展開' : '收合'"
        ></v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <!-- Options -->
    <v-list dense>
      <template v-for="item in $router.options.routes">
        <template v-if="!!item.meta && !!item.meta.menuShow">
          <!-- without children -->
          <v-list-item
            link
            v-if="!item.children"
            :key="item.name"
            @click="goLinks(item.path)"
          >
            <template v-if="!!item.meta">
              <v-list-item-icon v-if="!!item.meta.icon">
                <v-icon>{{ item.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                !item.meta.menuLabel ? "" : item.meta.menuLabel
              }}</v-list-item-title>
            </template>
          </v-list-item>
          <!-- with children -->
          <v-list-group
            :group="item.name"
            :prepend-icon="
              !!item.meta && !!item.meta.icon ? item.meta.icon : ''
            "
            no-action
            :key="item.name"
            v-else
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="
                    !!item.meta && !!item.meta.menuLabel
                      ? item.meta.menuLabel
                      : ''
                  "
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              v-for="child in item.children"
              :key="child.name"
              @click="goLinks(child.path)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="
                    !!child.meta && !!child.meta.menuLabel
                      ? child.meta.menuLabel
                      : ''
                  "
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import LoginVerify from '@util/common/loginVerify.js';
import routeHandler from '@util/common/routeHandler.js';

export default {
  name: 'Sidebar',
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
          breakpoint: '', // 視窗大小段點
          switch: null // 手動控制開關
        };
      }
    }
  },
  data () {
    return {
      logger: {},
      isCollapse: [],
      drawer: {
        mini: false,
        permanent: this.initVars.breakpoint == null ? false : !(['xs'].indexOf(this.initVars.breakpoint) >= 0),
        expandOnHover: !(['xs'].indexOf(this.initVars.breakpoint) >= 0)
      }
    };
  },
  watch: {
    /**
     * [監聽 breakpoint] 視窗大小控制顯示
     */
    'initVars.breakpoint': {
      handler (newVal, oldVal) {
        // console.log('[sidebar][watch] ... breakpoint');
        this.drawer.permanent = !(['xs'].indexOf(newVal) >= 0);
      },
      immediate: true
    },
    /**
     * [監聽 switch] 手動控制顯示(來源裝置是手機才會用)
     */
    'initVars.switch': {
      handler (newVal, oldVal) {
        // console.log(`[Sidebar][switch] ... ${!!newVal} / ${!!oldVal}`);
        this.drawer.permanent = !this.drawer.permanent;
        if (this.drawer.permanent) {
          this.drawer.mini = false;
        }
      }
    }
  },
  methods: {
    /**
     * [initSet] 初始化
     */
    initSet () {
      // 登入者
      this.logger = LoginVerify.getLogger();
      this.calCollapse();
    },
    /**
     * [calCollapse]
     */
    calCollapse () {
      // console.log('[Sidebar][calCollapse] ...');
      const _isCollapse = [];
      let r = null;
      for (let i = 0; i < this.$router.options.routes.length; i++) {
        r = this.$router.options.routes[i];
        // console.log(`i: ${i}，${r.name} : ${this.$route.matched[0].name}`);
        _isCollapse.push(r.name === this.$route.matched[0].name);
      }
      this.$set(this, 'isCollapse', _isCollapse);
      // console.log(this.isCollapse);
    },
    /**
     * [goLinks] 點擊後前去連結
     * @param string path
     */
    goLinks (_path = '') {
      if (_path !== '') {
        // console.log(`go path: ${_path}`);
        routeHandler.nextRoute(this, { path: _path });
      }
    },
    /**
     * [clickOnOff] 手動開合
     */
    clickOnOff () {
      // console.log('[sidebar][clickOnOff] ... ' + ['xs'].indexOf(this.initVars.breakpoint));
      this.drawer.mini = !this.drawer.mini;
      // 來源裝置是手機，則直接關閉
      if (['xs'].indexOf(this.initVars.breakpoint) >= 0) {
        this.drawer.permanent = false;
      }
    }

  },
  created () {
    this.initSet();
  },
  mounted () {
    // console.log('[Sidebar][mounted] ...');
    // console.log(this.drawer);
    // console.log(this.$route);
  },
  updated () {
    // console.log('[Sidebar][updated] ...');
    this.calCollapse();
    // console.log(this.drawer);
    // console.log(this.isCollapse);
  }
};
</script>
