<template>
  <v-app-bar app>
    <v-app-bar-nav-icon
      class="d-flex d-sm-none"
      @click.stop="switchSidebar()"
    ></v-app-bar-nav-icon>
    <div class="ml-2">{{ title }}</div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Header',
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
          breakpoint: ''
        };
      }
    }
  },
  data () {
    return {
      title: this.$route.meta.menuLabel,
      active: {
        sidebar: false
      }
    };
  },
  methods: {
    switchSidebar () {
      // console.log(`[Header][switchSidebar] ... ${!this.active.sidebar}`);
      this.active.sidebar = !this.active.sidebar;
      this.$emit('update:switchSidebar', this.active.sidebar);
    }
  }
};
</script>
