<template>
  <v-flex>
    <Header
      :initVars="headerVars"
      v-on:update:switchSidebar="switchSidebar"
    ></Header>
    <Sidebar :initVars="sidebarVars"></Sidebar>
    <slot name="layout-mainbox-custom">
      <Mainbox :initVars="mainboxVars"></Mainbox>
    </slot>
    <!-- <v-footer app> v-footer </v-footer> -->
  </v-flex>
</template>

<script>
import deviceInfor from '@util/common/deviceInfor.js';
import Header from '@/layouts/basic/Header';
import Sidebar from '@/layouts/basic/Sidebar';
import Mainbox from '@/layouts/basic/Mainbox';
export default {
  name: 'LayoutBasic',
  components: { Header, Sidebar, Mainbox },
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      breakpoint: '',
      headerVars: this.initVars.header != null ? this.initVars.header : {},
      sidebarVars: this.initVars.sidebar != null ? this.initVars.sidebar : {},
      mainboxVars: this.initVars.mainbox != null ? this.initVars.mainbox : {}
    };
  },
  watch: {
    '$vuetify.breakpoint.width': {
      handler (newWidth, oldWidth) {
        this.breakpoint = deviceInfor.getInfor(this).breakpoint;
        this.$set(this.sidebarVars, 'breakpoint', this.breakpoint);
      },
      immediate: true
    }
  },
  methods: {
    /**
     * [switchSidebar] 動態控制sidebar
     */
    switchSidebar (status = null) {
      // console.log(`[basic][switchSidebar] ... ${status}`);
      this.$set(this.sidebarVars, 'switch', !!status);
    }
  },
  created () {
    // console.log('[layout/basic][created] ...');
  },
  updated () {
    // console.log('[layout/basic][updated] ...');
  }
};
</script>
