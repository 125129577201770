<template>
  <v-main class="mainbox-main">
    <slot name="layout-mainbox-main">
      <v-container fluid class="pa-3 pa-sm-8 fill-height">
        <v-row class="align-self-start">
          <v-col>
            <slot name="layout-mainbox-inner">
              <router-view></router-view>
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </slot>
  </v-main>
</template>
<script>
export default {
  name: 'Mainbox',
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {};
  },
  created () {
    // console.log('[mainbox][created]... ');
  },
  updated () {
    // console.log('[mainbox][updated] ...');
  }
};
</script>
